var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-dialog',{attrs:{"position":"bottom","maximized":""},on:{"show":() => {
      this.$refs.searchInput.focus()
    },"keyup":_vm.initiateSearch},model:{value:(_vm.dialogState),callback:function ($$v) {_vm.dialogState=$$v},expression:"dialogState"}},[_c('q-card',{staticClass:"mobileSearchDialog"},[_c('q-card-section',{staticClass:"q-pt-none"},[_c('q-input',{ref:"searchInput",staticClass:"col",attrs:{"type":"search","borderless":"","dense":"","bg-color":"white","color":"dark","placeholder":"What are you looking for?","autocomplete":"off","maxlength":"20"},on:{"focus":function($event){_vm.searchText = ''}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-btn',{attrs:{"dense":"","flat":"","ripple":false},on:{"click":_vm.searchClicked}},[_c('SvgIcon',{attrs:{"className":"q-ml-sm q-mr-sm","color":"grey","size":"1rem","icon":"simple-search"}})],1)]},proxy:true},{key:"prepend",fn:function(){return [_c('q-btn',{attrs:{"dense":"","flat":"","ripple":false},on:{"click":function($event){_vm.dialogState = false}}},[_c('SvgIcon',{attrs:{"color":"grey","size":"1rem","icon":"back-arrow-line"}})],1)]},proxy:true}]),model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}},[_c('div',{staticClass:"h-search-xs-dropdown"},[_c('q-scroll-area',{staticClass:"searchXsHeight",staticStyle:{"max-width":"100%"},attrs:{"thumb-style":_vm.scrollAreaThumbStyle,"bar-style":_vm.scrollAreaBarStyle,"visible":_vm.scrollAreaVisible}},[_c('div',{staticClass:"h-search-xs-1"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchText.length >= 3),expression:"searchText.length >= 3"}]},[_c('h5',[_vm._v("\n                  Showing Result for "),_c('strong',[_vm._v(_vm._s(_vm.searchText))])]),_c('div',{staticClass:"h-search-xs-list"},[_c('q-list',{directives:[{name:"show",rawName:"v-show",value:(!_vm.searchingProduct),expression:"!searchingProduct"}]},_vm._l((_vm.productsByKeyword.items),function(product){return _c('q-item',{key:product.productID},[_c('q-item-section',{attrs:{"avatar":""}},[_c('router-link',{attrs:{"to":{
                            name: 'Detail',
                            params: { seoName: product.seoName },
                          }}},[_c('q-img',{attrs:{"height":"50","widith":"50","ratio":1,"src":product.images && product.images.length
                                ? product.images[0].imageUrl
                                : _vm.$options.filters.defaultProductImage('')}})],1)],1),_c('q-item-section',[_c('router-link',{attrs:{"to":{
                            name: 'Detail',
                            params: { seoName: product.seoName },
                          }}},[_vm._v("\n                          "+_vm._s(product.title)+"\n                        ")])],1)],1)}),1),_c('q-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchingProduct),expression:"searchingProduct"}]},_vm._l((5),function(n){return _c('q-item',{key:n},[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-skeleton',{attrs:{"width":"50px","height":"50px"}})],1),_c('q-item-section',[_c('q-skeleton',{attrs:{"type":"text","width":"100%"}}),_c('q-skeleton',{attrs:{"type":"text","width":"30%"}})],1)],1)}),1),_c('EmptyComponent',{directives:[{name:"show",rawName:"v-show",value:(
                      !_vm.searchingProduct && _vm.productsByKeyword.items.length == 0
                    ),expression:"\n                      !searchingProduct && productsByKeyword.items.length == 0\n                    "}],attrs:{"imgWidth":160,"image":"images/empty-search.png"}},[_c('p',[_vm._v("\n                      No product available matching  \n                      "),_c('strong',[_vm._v("\n                        "+_vm._s(_vm.searchText)+"\n                      ")])])])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchText.length < 3),expression:"searchText.length < 3"}]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.frequentSearches.length),expression:"frequentSearches.length"}],staticClass:"h-search-xs-2"},[_c('h5',[_vm._v("Frequent Searches")]),_c('q-list',{attrs:{"padding":""}},_vm._l((_vm.frequentSearches),function(item,index){return _c('router-link',{key:`${item}-${index}`,staticClass:"no-underline",attrs:{"to":{
                        name: 'Search',
                        query: { q: item },
                      }},nativeOn:{"click":function($event){_vm.focus = false}}},[_c('q-item',{staticClass:"items-center"},[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-icon',{attrs:{"color":"dark","name":"search"}})],1),_c('q-item-section',[_vm._v(_vm._s(item))])],1)],1)}),1)],1),(Object.keys(_vm.trendingProducts).length)?[_c('h5',[_vm._v("Trending")]),_c('div',{staticClass:"h-search-xs-products"},_vm._l((_vm.trendingProducts),function(product,productId){return _c('q-card',{key:productId,staticClass:"product-items--card",attrs:{"flat":""}},[_vm._l((product.categories),function(category,index){return _c('q-badge',{key:`${category.categoryID}-${index}`,staticClass:"product-badge",attrs:{"color":"primary"}},[_vm._v("\n                        "+_vm._s(_vm.$options.filters.titleCase(category.categoryName))+"\n                      ")])}),_c('div',{staticClass:"product-image"},[_c('router-link',{staticClass:"no-underline",attrs:{"to":{
                            name: 'Detail',
                            params: { seoName: product.seoName },
                          }}},[_c('q-img',{attrs:{"src":_vm._f("defaultProductImage")(product.image),"ratio":1}})],1)],1),_c('router-link',{staticClass:"no-underline",attrs:{"to":{
                          name: 'Detail',
                          params: { seoName: product.seoName },
                        }}},[_c('q-card-section',{staticClass:"q-pt-sm q-pl-none q-pr-none q-pb-none"},[_c('div',{staticClass:"row column no-wrap"},[_c('div',{staticClass:"cursor-pointer col product-title ellipsis-2-lines"},[_vm._v("\n                              "+_vm._s(product.productName)+"\n                            ")])])])],1)],2)}),1)]:_vm._e()],2)])]),(_vm.productsByKeyword.items && _vm.productsByKeyword.items.length)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchText.length >= 3 && !_vm.searchingProduct),expression:"searchText.length >= 3 && !searchingProduct"}],staticClass:"h-search-2"},[_c('router-link',{staticClass:"more-products",attrs:{"to":{
                name: 'Search',
                query: { q: _vm.searchText },
              }},nativeOn:{"click":function($event){_vm.focus = false}}},[_vm._v("\n              See All Results\n            ")])],1):_vm._e()],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }