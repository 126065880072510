<template>
  <q-dialog
    @show="
      () => {
        this.$refs.searchInput.focus()
      }
    "
    position="bottom"
    maximized
    @keyup="initiateSearch"
    v-model="dialogState"
  >
    <q-card class="mobileSearchDialog">
      <q-card-section class="q-pt-none">
        <q-input
          type="search"
          class="col"
          borderless
          dense
          bg-color="white"
          color="dark"
          placeholder="What are you looking for?"
          autocomplete="off"
          ref="searchInput"
          v-model="searchText"
          @focus="searchText = ''"
          maxlength="20"
        >
          <div class="h-search-xs-dropdown">
            <q-scroll-area
              class="searchXsHeight"
              :thumb-style="scrollAreaThumbStyle"
              :bar-style="scrollAreaBarStyle"
              :visible="scrollAreaVisible"
              style="max-width: 100%"
            >
              <div class="h-search-xs-1">
                <div v-show="searchText.length >= 3">
                  <h5>
                    Showing Result for <strong>{{ searchText }}</strong></h5
                  >
                  <div class="h-search-xs-list">
                    <q-list v-show="!searchingProduct">
                      <q-item
                        v-for="product in productsByKeyword.items"
                        :key="product.productID"
                      >
                        <q-item-section avatar>
                          <router-link
                            :to="{
                              name: 'Detail',
                              params: { seoName: product.seoName },
                            }"
                          >
                            <q-img
                              height="50"
                              widith="50"
                              :ratio="1"
                              :src="
                                product.images && product.images.length
                                  ? product.images[0].imageUrl
                                  : $options.filters.defaultProductImage('')
                              "
                            />
                          </router-link>
                        </q-item-section>
                        <q-item-section>
                          <router-link
                            :to="{
                              name: 'Detail',
                              params: { seoName: product.seoName },
                            }"
                          >
                            {{ product.title }}
                          </router-link>
                        </q-item-section>
                      </q-item>
                    </q-list>
                    <q-list v-show="searchingProduct">
                      <q-item v-for="n in 5" :key="n">
                        <q-item-section avatar>
                          <q-skeleton width="50px" height="50px" />
                        </q-item-section>
                        <q-item-section>
                          <q-skeleton type="text" width="100%" />
                          <q-skeleton type="text" width="30%" />
                        </q-item-section>
                      </q-item>
                    </q-list>

                    <EmptyComponent
                      :imgWidth="160"
                      v-show="
                        !searchingProduct && productsByKeyword.items.length == 0
                      "
                      image="images/empty-search.png"
                    >
                      <p>
                        No product available matching &nbsp;
                        <strong>
                          {{ searchText }}
                        </strong>
                      </p>
                    </EmptyComponent>
                  </div>
                </div>
                <div v-show="searchText.length < 3">
                  <div class="h-search-xs-2" v-show="frequentSearches.length">
                    <h5>Frequent Searches</h5>
                    <q-list padding>
                      <router-link
                        v-for="(item, index) in frequentSearches"
                        :key="`${item}-${index}`"
                        :to="{
                          name: 'Search',
                          query: { q: item },
                        }"
                        class="no-underline"
                        @click.native="focus = false"
                      >
                        <q-item class="items-center">
                          <q-item-section avatar>
                            <q-icon color="dark" name="search" />
                          </q-item-section>
                          <q-item-section>{{ item }}</q-item-section>
                        </q-item>
                      </router-link>
                    </q-list>
                  </div>
                  <template v-if="Object.keys(trendingProducts).length">
                    <h5>Trending</h5>
                    <div class="h-search-xs-products">
                      <q-card
                        v-for="(product, productId) in trendingProducts"
                        :key="productId"
                        class="product-items--card"
                        flat
                      >
                        <q-badge
                          v-for="(category, index) in product.categories"
                          :key="`${category.categoryID}-${index}`"
                          color="primary"
                          class="product-badge"
                        >
                          {{ $options.filters.titleCase(category.categoryName) }}
                        </q-badge>
                        <div class="product-image">
                          <router-link
                            :to="{
                              name: 'Detail',
                              params: { seoName: product.seoName },
                            }"
                            class="no-underline"
                          >
                            <q-img
                              :src="product.image | defaultProductImage"
                              :ratio="1"
                            />
                          </router-link>
                        </div>
                        <router-link
                          :to="{
                            name: 'Detail',
                            params: { seoName: product.seoName },
                          }"
                          class="no-underline"
                        >
                          <q-card-section
                            class="q-pt-sm q-pl-none q-pr-none q-pb-none"
                          >
                            <div class="row column no-wrap">
                              <div
                                class="cursor-pointer col product-title ellipsis-2-lines"
                              >
                                {{ product.productName }}
                              </div>
                            </div>
                          </q-card-section>
                        </router-link>
                      </q-card>
                    </div>
                  </template>
                </div>
              </div>
            </q-scroll-area>
            <div
              class="h-search-2"
              v-show="searchText.length >= 3 && !searchingProduct"
              v-if="productsByKeyword.items && productsByKeyword.items.length"
            >
              <router-link
                class="more-products"
                :to="{
                  name: 'Search',
                  query: { q: searchText },
                }"
                @click.native="focus = false"
              >
                See All Results
              </router-link>
            </div>
          </div>
          <template v-slot:append>
            <q-btn dense flat :ripple="false" @click="searchClicked">
              <SvgIcon
                className="q-ml-sm q-mr-sm"
                color="grey"
                size="1rem"
                icon="simple-search"
              />
            </q-btn>
          </template>
          <template v-slot:prepend>
            <q-btn dense flat :ripple="false" @click="dialogState = false">
              <SvgIcon color="grey" size="1rem" icon="back-arrow-line" />
            </q-btn>
          </template>
        </q-input>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MobileSearchDialog',
  // props: {
  //   currentLocationId: {
  //     type: String,
  //     default: null,
  //   },
  // },
  computed: {
    ...mapGetters('common', [
      'frequentSearches',
      'trendingProducts',
      'currentDeliveryModes',
    ]),
    ...mapGetters('category', ['searchCategory']),
    dialogState: {
      get() {
        return this.getDialogState('MobileSearchDialog')
      },
      set(val) {
        this.changeDialogState({
          dialog: 'MobileSearchDialog',
          val,
        })
      },
    },
  },
  methods: {
    initiateSearch(event) {
      if (
        event.keyCode == 13 &&
        this.searchText &&
        !(
          this.$route.name == 'Search' && this.$route.query.q == this.searchText
        )
      ) {
        this.$router
          .push({ name: 'Search', query: { q: this.searchText } })
          .catch(() => {})
      }

      if (event.target.value.length >= 3) {
        if (this.searchValue === event.target.value) return

        this.searchingProduct = true
        this.searchValue = event.target.value
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        this.timer = setTimeout(() => {
          this.getProductByKeyword(this.searchValue)
        }, 400)
      }
    },
    getProductByKeyword(value) {
      if (!this.currentLocationId) return

      let params = {
        searchKeyword: value,
        PageSize: 20,
        isFrequentSearch: true,
        categoryID: this.searchCategory.categoryID,
        ...this.currentDeliveryModes,
      }

      this.searchingProduct = true
      this.$store.dispatch('product/getByKeyword', params).then((data) => {
        if (data) this.productsByKeyword = data
        this.searchingProduct = false
      })
    },
    searchClicked() {
      if (
        this.searchText &&
        !(
          this.$route.name == 'Search' && this.$route.query.q == this.searchText
        )
      ) {
        this.$router
          .push({ name: 'Search', query: { q: this.searchText } })
          .catch(() => {})
      }
    },
  },
  data() {
    return {
      searchText: '',
      searchValue: '',
      timer: null,
      productsByKeyword: {
        items: [],
      },
      searchingProduct: false,
    }
  },
}
</script>
<style lang="scss">
.mobileSearchDialog {
  border-radius: 0 !important;
  padding: 0;
  max-height: 100vh !important;
  height: 100vh !important;
  position: fixed;
  top: 0;
  .relative-position {
    position: initial;
  }
  .q-field__control,
  .q-field__marginal {
    height: 60px;
  }
  .q-field__prepend,
  .q-field__append {
    width: 60px;
    justify-content: center;
  }
  .q-card__section--vert {
    padding: 0;
  }
  .q-field {
    border-bottom: 2px solid #f5f5f5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  .h-search-xs-dropdown {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% + 2px);
    height: calc(100vh - 62px);
    border-radius: 0 0 5px 5px;
    z-index: 9;
    .relative-position {
      position: relative;
    }
    .searchXsHeight {
      height: calc(100vh - 65px);
    }
    h5 {
      margin: 0;
      padding: 4px 16px;
      background: #f5f5f5;
      font-size: 13px;
      font-weight: bold;
    }
  }
  .h-search-xs-1,
  .h-search-xs-2 {
    /*padding-right: 10px;*/
  }
  .h-search-xs-list {
    .q-item {
      padding: 12px 16px;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      .q-img {
        width: 50px;
        height: 50px;
      }
      &:first-child {
        border: none;
      }
    }
    .q-item__section--main {
      display: block;
    }
    a {
      font-size: 14px;
      color: $tertiary;
      text-decoration: none;
    }
    span {
      a {
        color: $primary;
        color: var(--q-color-primary) !important;
        text-decoration: underline;
      }
    }
  }
  .q-list {
    a {
      font-size: 16px;
      color: $tertiary;
    }
  }
  .h-search-xs-products {
    overflow: auto;
    white-space: nowrap;
    margin: 5px;
    padding-bottom: 5px;
    &::-webkit-scrollbar {
      height: 9px;
    }
    &::-webkit-scrollbar-track {
      background: rgb(229, 229, 229);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(183, 183, 183);
      border: 2px solid rgb(229, 229, 229);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #b30000;
    }
    .q-card {
      padding: 12px;
      display: inline-block;
      max-width: 200px;
      min-height: auto;
      .product-badge {
        position: absolute;
        left: 8px;
        top: 8px;
        border-radius: 4px;
      }
      .product-image {
        padding: 30px 40px 30px;
      }
      .product-title {
        font-size: 15px;
      }
    }
  }
}
</style>
